import React, { CSSProperties } from 'react';
import {
  InputLabel,
  Select,
  Chip,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  createTheme,
} from '@mui/material';

function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

const checkIfSelected = (values: string[], currentItem: string) => {
  if (Array.isArray(values)) {
    return values.includes(currentItem);
  } else {
    return values === currentItem;
  }
};

const theme = createTheme();

const Selection = ({
  id,
  multiple = false,
  usingObjects = false,
  inputName,
  labelName,
  selectedValues,
  values,
  keyProperty = '',
  valueProperty = '',
  handleChange,
  color,
}: {
  id: string;
  multiple?: boolean;
  usingObjects?: boolean;
  inputName: string;
  labelName: string;
  selectedValues: any;
  values: any[];
  keyProperty?: string;
  valueProperty?: string;
  handleChange: any;
  color?: CSSProperties;
}) => {
  return (
    <FormControl
      style={{
        width: '400px',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
      }}
    >
      <InputLabel id={id + `-label`} style={color}>
        {labelName}
      </InputLabel>
      <Select
        multiple={multiple}
        labelId={id + `-label`}
        id={id}
        name={inputName}
        value={selectedValues}
        onChange={handleChange}
        input={<Input id={id + `-label-input`} />}
        renderValue={(selected) =>
          !isEmpty(selected) ? (
            Array.isArray(selected) ? (
              <div style={{ width: '400px' }}>
                {selected.map((value) =>
                  usingObjects ? (
                    <Chip key={value[keyProperty]} label={value[valueProperty]} />
                  ) : (
                    <Chip key={value} label={value} />
                  )
                )}
              </div>
            ) : (
              <div style={{ width: '400px' }}>
                {usingObjects ? (
                  <Chip
                    key={selected[keyProperty]}
                    label={selected[valueProperty].replace('_', ' ')}
                  />
                ) : (
                  <Chip key={selected} label={selected.replace('_', ' ')} />
                )}
              </div>
            )
          ) : (
            <></>
          )
        }
      >
        {values.map((value, index) =>
          keyProperty ? (
            usingObjects ? (
              <MenuItem key={index} value={value}>
                <Checkbox
                  checked={checkIfSelected(selectedValues[keyProperty], value[keyProperty])}
                />
                <ListItemText primary={value[valueProperty].replace('_', ' ')} />
              </MenuItem>
            ) : (
              <MenuItem key={index} value={value[keyProperty]}>
                <Checkbox checked={checkIfSelected(selectedValues, value[keyProperty])} />
                <ListItemText primary={value[valueProperty].replace('_', ' ')} />
              </MenuItem>
            )
          ) : (
            <MenuItem key={index} value={value}>
              <Checkbox checked={checkIfSelected(selectedValues, value)} />
              <ListItemText primary={value.replace('_', ' ')} />
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default Selection;
