import React, { useState, Fragment } from 'react';
import { Typography, Grid, createTheme, Card, Box } from '@mui/material';
import { Link } from 'react-router-dom';

import ErrorSnackbar from '../components/errorSnackbar';
import InfoSnackbar from '../components/infoSnackbar';
import LoadingBar from '../components/loadingBar';
import ModelCard from '../components/modelCard';

type Model = {
  id: string;
  title: string;
  description: string;
  link: string;
  imagePath: string;
};

const theme = createTheme();

// predictive models
const models: Model[] = [
  {
    id: 'Country_Viewer',
    title: 'Country Viewer',
    description: '',
    link: 'countryviewer',
    imagePath: '/images/icons/world_blue.png',
  },
  {
    id: 'Hazard_Viewer',
    title: 'Hazard Viewer',
    description: '',
    link: 'hazardviewer',
    imagePath: '/images/icons/wave blue.png',
  },
];

// causal models
const causal_models: Model[] = [
  {
    id: 'Social_Tension',
    title: 'Social Tensions Causal',
    description: '',
    link: 'causalmonitor',
    imagePath: '/images/icons/social_tensions_blue.png',
  },
  {
    id: 'Forced_Migration',
    title: 'Forced Migration Causal',
    description: '',
    link: 'causalmonitor',
    imagePath: '/images/icons/migration_dark_blue.png',
  },
];

// dev models
const dev_causal_models: Model[] = [
  {
    id: 'Inequality_Causal',
    title: 'Inequality Causal',
    description: '',
    link: '',
    imagePath: '/images/icons/equality.png',
  },
  {
    id: 'Destabilised_Institutions_Causal',
    title: 'Destabilised Instutions Causal',
    description: '',
    link: '',
    imagePath: '/images/icons/institutions.png',
  },
  {
    id: 'Resource_Scarcity_Causal',
    title: 'Resource Sparcity Causal',
    description: '',
    link: '',
    imagePath: '/images/icons/resource_scarcity.png',
  },
];

type MonitorManagerProps = {};

type MonitorManagerState = {
  loading: boolean;
  success: string;
  error: string;
};

function MonitorManager(props: MonitorManagerProps) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  return (
    <Fragment>
      <Card
        sx={{
          argin: theme.spacing(1),
          padding: theme.spacing(1),
          textAlign: 'center',
          marginTop: '3.5%',
        }}
      >
        <Typography
          sx={{
            fontSize: '1.5em',
            [theme.breakpoints.down('xs')]: {
              fontSize: '1.2em',
            },
          }}
        >
          This monitor interactively visualizes the HCSS Climate Security Risk Methodology.
          Exploration of the data contained within the monitor is intended to support policy makers
          and researchers in understanding insecurity relating to climate change. The monitor is
          organized into tabs to facilitate cross country, and hazard analysis. The data underlying
          the monitor and the Climate Security Risk Methodology are also accessible.
        </Typography>
      </Card>
      <Box>
        {/* Predictive Modelling */}
        <Grid sx={{ marginTop: '2%' }} container justifyContent="center" spacing={4}>
          {models.map((element: Model) => (
            <Grid xs="auto" key={element.id} item>
              <Link style={{ textDecoration: 'none' }} to={`/${element.link}`}>
                <ModelCard
                  modId={element.id}
                  modTitle={element.title}
                  description={element.description}
                  imagePath={element.imagePath}
                />
              </Link>
            </Grid>
          ))}
        </Grid>

        {/* Causal Monitors */}
        <Grid sx={{ marginTop: '2%' }} container justifyContent="center" spacing={4}>
          {causal_models.map((element: Model) => (
            <Grid xs="auto" key={element.id} item>
              <Link style={{ textDecoration: 'none' }} to={`/${element.link}/${element.id}`}>
                <ModelCard
                  modId={element.id}
                  modTitle={element.title}
                  description={element.description}
                  imagePath={element.imagePath}
                />
              </Link>
            </Grid>
          ))}
        </Grid>

        {/* dev Monitors (don't have a link yet) */}
        <Grid sx={{ marginTop: '2%' }} container justifyContent="center" spacing={4}>
          {dev_causal_models.map((element: Model) => (
            <Grid xs="auto" key={element.id} item>
              <ModelCard
                modId={element.id}
                modTitle={element.title}
                description={element.description}
                imagePath={element.imagePath}
              ></ModelCard>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Flag based display of error snackbar */}
      {error.length > 0 && <ErrorSnackbar onClose={() => setError('')} message={error} />}

      {/* Flag based display of loadingbar */}
      {loading && <LoadingBar />}

      {/* Flag based display of info snackbar */}
      {success.length > 0 && <InfoSnackbar onClose={() => setSuccess('')} message={success} />}
    </Fragment>
  );
}

export default MonitorManager;
