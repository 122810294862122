import React, { Fragment } from 'react';
import { CssBaseline, createTheme } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import MonitorManager from '../pages/monitorManager';
import MonitorViewer from '../pages/monitorViewer';
import CausalViewer from '../pages/causalViewer';
import CountryViewer from '../pages/countryViewer';

import AppHeader from './appHeader';
import Footer from './footer';

const theme = createTheme();

const App = () => (
  <Fragment>
    <CssBaseline />

    <AppHeader />

    <div
      style={{
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(0.5),
        },
      }}
    >
      <Routes>
        <Route path="/" element={<MonitorManager />} />
        <Route path="/hazardviewer/" element={<MonitorViewer />} />
        <Route path="/hazardviewer/:id" element={<MonitorViewer />} />
        <Route path="/causalmonitor/:id" element={<CausalViewer />} />
        <Route path="/countryviewer/" element={<CountryViewer />} />
        <Route path="/countryviewer/:id" element={<CountryViewer />} />
      </Routes>
    </div>

    <Footer />
  </Fragment>
);

export default App;
