import React from 'react';
import { Snackbar, IconButton, SnackbarContent } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';

const AUTO_HIDE_DURATION = 6000;

const InfoSnackbar = ({ message, onClose }: { message: string; onClose: any }) => (
  <Snackbar open autoHideDuration={AUTO_HIDE_DURATION} onClose={onClose}>
    <SnackbarContent
      message={
        <span>
          <CheckIcon />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon
            sx={{
              fontSize: 20,
            }}
          />
        </IconButton>,
      ]}
    />
  </Snackbar>
);

export default InfoSnackbar;
