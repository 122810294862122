import React, { Fragment, useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import Selection from '../components/selection';
import ErrorSnackbar from '../components/errorSnackbar';
import InfoSnackbar from '../components/infoSnackbar';
import LoadingBar from '../components/loadingBar';
import BulletPlot from '../components/bulletPlot';

import { ModelService } from '../services';
import { Country, BulletPlotData } from '../model';

type CountryViewerProps = {};

type CountryViewerState = {
  countries: Country[];
  countrySelected: Country;
  data: BulletPlotData[];

  service: ModelService;

  loading: boolean;
  success: string;
  error: string;
};

function CountryViewer(props: CountryViewerProps) {
  const [countries, setCountries] = useState<Country[]>([]);
  const [countrySelected, setCountrySelected] = useState<Country>({} as Country);
  const [data, setData] = useState<BulletPlotData[]>([]);

  const [service, setService] = useState<ModelService>(ModelService.getInstance());

  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    let countryRequested = 'NLD';
    getCountries().then((countries) => {
      setCountries(countries.sort((a, b) => (a.name > b.name ? 1 : -1)));
      setCountrySelected(
        countries.find((country) => country.iso3c === countryRequested) || (null as any)
      );
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (countrySelected.iso3c) getPlotData(countrySelected);
  }, [countrySelected]);

  const getCountries = async () => {
    try {
      return await service.getCountries();
    } catch {
      setError('Could not talk with backend');
    }

    return [];
  };

  const getPlotData = (country: Country) => {
    service.getBulletPlotData(country).then((data) => {
      setCountrySelected(country);
      setData(data);
    });
  };

  const handleChange = (evt: React.ChangeEvent<{ value: unknown }>) => {
    const country = evt.target.value as Country;
    if (country) {
      getPlotData(country);
      routingFunction(country.iso3c);
    } else {
      setCountrySelected(null as any);
      setData([]);
    }
  };

  const routingFunction = (param: string) => {
    /*
    props.history.push({
      pathname: '/countryviewer/' + param,
      state: param,
    });
    */
  };

  return (
    <Fragment>
      {countries.length > 0 && (
        <Fragment>
          <Typography variant="h4">Country View</Typography>

          <Selection
            id="country-selection"
            inputName="country"
            labelName="Countries"
            selectedValues={countrySelected}
            usingObjects={true}
            values={countries}
            keyProperty="iso3c"
            valueProperty="name"
            handleChange={handleChange}
          />
        </Fragment>
      )}

      {countrySelected && (
        <Fragment>
          <Typography variant="h5">{countrySelected.name}</Typography>

          <BulletPlot data={data} id={countrySelected.iso3c}></BulletPlot>
        </Fragment>
      )}

      {/* Flag based display of loadingbar */}
      {loading && <LoadingBar />}

      {/* Flag based display of error snackbar */}
      {error.length > 0 && <ErrorSnackbar onClose={() => setError('')} message={error} />}

      {/* Flag based display of info snackbar */}
      {success.length > 0 && <InfoSnackbar onClose={() => setSuccess('')} message={success} />}
    </Fragment>
  );
}

export default CountryViewer;
