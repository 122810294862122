import React, { Fragment, useEffect, useState } from 'react';

import ErrorSnackbar from '../components/errorSnackbar';
import LoadingBar from '../components/loadingBar';

import { ModelService } from '../services';
import { DataSource } from '../model';
import DataTable from './dataTable';

type DataSourcesState = {
  service: ModelService;
  data: DataSource[];

  loading: boolean;
  success: string;
  error: string;
};

const columns = [
  { title: 'Variable', field: 'Variable', width: 300 },
  { title: 'Source', field: 'Source', width: 150 },
  { title: 'Date', field: 'Date', width: 100 },
  { title: 'Link', field: 'Link', width: 400 },
];

function DataSources() {
  const [data, setData] = useState<DataSource[]>([]);

  const [service] = useState(ModelService.getInstance());

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    getDataSources().then((data) => setData(data));
  }, []);

  const getDataSources = async () => {
    try {
      return await service.getDataSources();
    } catch {
      setError('Could not talk with backend');
    }

    return [];
  };

  return (
    <Fragment>
      {data.length > 0 && <DataTable data={data} columns={columns} />}

      {/* Flag based display of loadingbar */}
      {loading && <LoadingBar />}

      {/* Flag based display of error snackbar */}
      {length > 0 && <ErrorSnackbar onClose={() => setError('')} message={error} />}
    </Fragment>
  );
}

export default DataSources;
