import React from 'react';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';

import { COLOR_SCHEMA } from '../services';
import ToolTip from './tooltip';

const ScatterPlot = ({ hazard, data, onClick }: { hazard: string; data: any[]; onClick: any }) => {
  return (
    <div style={{ height: 400 }}>
      <ResponsiveScatterPlot
        data={data}
        margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
        xScale={{ type: 'linear', min: 0, max: 100 }}
        yScale={{ type: 'linear', min: 0, max: 100 }}
        blendMode="multiply"
        colors={(node) => {
          // @ts-ignore
          return COLOR_SCHEMA[node.serieId];
        }}
        axisTop={null}
        axisRight={null}
        onClick={onClick}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Impact',
          legendPosition: 'middle',
          legendOffset: 46,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Probability',
          legendPosition: 'middle',
          legendOffset: -60,
        }}
        tooltip={(node) => {
          {
            // @ts-ignore
            return <ToolTip id={node.node?.data?.country} hazard={hazard} />;
          }
        }}
        legends={[]}
      />
    </div>
  );
};

export default ScatterPlot;
