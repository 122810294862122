import React, { CSSProperties, Fragment, useEffect, useState } from 'react';
import { Typography, Grid, Card, CardContent, createTheme } from '@mui/material';

import ErrorSnackbar from '../components/errorSnackbar';
import InfoSnackbar from '../components/infoSnackbar';
import LoadingBar from '../components/loadingBar';
import NetworkPlot from '../components/networkPlot';
import NodeInformation from '../components/nodeInformation';
import Selection from '../components/selection';

import { ModelService } from '../services';
import { CausalData, Option, Node } from '../model';
import variableExplanation from '../data/variable_explanation.json';

function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

type CausalViewerProps = {};

type CausalViewerState = {
  monitorId: string;
  monitorReadable: string;
  description: string;
  treatment: Node[];
  treatment_selected: Node;
  outcome: Node[];
  outcome_selected: Node;
  networkData: CausalData;
  option: Option;
  selectedNode: string;
  selectedEdge: string;

  service: ModelService;

  loading: boolean;
  success: string;
  error: string;
};

const theme = createTheme();

function CausalViewer(props: CausalViewerProps) {
  const [monitorId, setMonitorId] = useState<string>('');
  const [monitorReadable, setMonitorReadable] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [treatment, setTreatment] = useState<Node[]>([]);
  const [treatment_selected, setTreatmentSelected] = useState<Node>({} as Node);
  const [outcome, setOutcome] = useState<Node[]>([]);
  const [outcome_selected, setOutcomeSelected] = useState<Node>({} as Node);
  const [networkData, setNetworkData] = useState<CausalData>({} as CausalData);
  const [option, setOption] = useState<Option>({} as Option);
  const [selectedNode, setSelectedNode] = useState<string>('');
  const [selectedEdge, setSelectedEdge] = useState<string>('');

  const [service, setService] = useState<ModelService>(ModelService.getInstance());

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    let monitorId = window.location.pathname.split('/').pop() || '';
    let monitorReadable = monitorId.replaceAll('_', ' '); // create printable monitor name based on ID
    const explanation = variableExplanation.find((element) => element.variable === monitorId);

    setMonitorId(monitorId);
    setMonitorReadable(monitorReadable);
    setDescription(explanation?.explanation || '');
  }, []);

  useEffect(() => {
    if (monitorId) getMonitorData();
  }, [monitorId]);

  const getNode = (id: string, networkData: CausalData): Node => {
    return (
      networkData.nodes.find((node) => id === node.id) || {
        id: '',
        title: '',
        case: '',
        color: '',
        label: '',
        level: 0,
        hidden: false,
        font: { color: '' },
      }
    );
  };

  const getMonitorData = async () => {
    let networkData = await service.getNetworkData(monitorId);

    if (!networkData.options) {
      setError('No data found for this monitor');
      return;
    }

    let treatment = Array.from(
      new Set(networkData.options.map((option) => getNode(option.treatment, networkData)))
    );
    let outcome = Array.from(
      new Set(networkData.options.map((option) => getNode(option.outcome, networkData)))
    );

    setNetworkData(networkData);
    setTreatment(treatment);
    setOutcome(outcome);

    setLoading(false);
  };

  const updateOption = () => {
    if (!isEmpty(outcome_selected) && !isEmpty(treatment_selected)) {
      let option = networkData.options.find(
        (opt) => opt.outcome === outcome_selected.id && opt.treatment == treatment_selected.id
      ) as Option;
      setOption(option);
    }
  };

  const handleChange = (evt: React.ChangeEvent<{ value: unknown }>) => {
    let value = evt.target.value as Node;

    // @ts-ignore
    if (evt.target.name === 'treatment') {
      setTreatmentSelected(value);

      // @ts-ignore
    } else if (evt.target.name === 'outcome') {
      setOutcomeSelected(value);
    }
  };

  const onNetworkClick = (element: string, value: string) => {
    if (value) {
      if (element === 'node') {
        setSelectedNode(value);
      } else if (element === 'edge') {
        setSelectedEdge(value);
      }
    }
  };

  const getCausalEffect = () => {
    let causal_effect = '';

    // display causal effect when present
    if (option.causal_effect && !isNaN(option.causal_effect)) {
      let ce = Number(option.causal_effect);
      causal_effect = ': ' + ce.toFixed(2);
    }

    return causal_effect;
  };

  const getCausalEffectColor = () => {
    const ce = Number(option.causal_effect);
    let causal_effect_color = '';

    if (ce > 0) {
      causal_effect_color = 'green';
    } else {
      causal_effect_color = 'red';
    }

    return {
      '--color': causal_effect_color,
    } as CSSProperties;
  };

  return (
    <Fragment>
      {networkData.edges && networkData.edges.length > 0 ? (
        // monitor data present
        <Fragment>
          <Typography variant="h4">{monitorReadable}</Typography>
          <Typography sx={{ marginBottom: theme.spacing(1) }}>
            {' '}
            {'Please refer to the help for further information regarding the causal models. ' +
              description}{' '}
          </Typography>

          {/* Dropdown selection for filter */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Card sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                  <NodeInformation data={selectedNode} />
                </CardContent>
              </Card>

              <Card sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Cause Selection
                  </Typography>
                  <Selection
                    id="treatment-selection"
                    inputName="treatment"
                    labelName="Cause"
                    selectedValues={treatment_selected}
                    usingObjects={true}
                    values={treatment}
                    keyProperty="id"
                    valueProperty="label"
                    handleChange={handleChange}
                    color={getCausalEffectColor()}
                  />

                  {treatment_selected && (
                    <Selection
                      id="outcome-selection"
                      inputName="outcome"
                      labelName="Effect"
                      selectedValues={outcome_selected}
                      usingObjects={true}
                      values={outcome}
                      keyProperty="id"
                      valueProperty="label"
                      handleChange={handleChange}
                      color={getCausalEffectColor()}
                    />
                  )}
                </CardContent>
              </Card>

              <Card sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Causal Paths {getCausalEffect()}{' '}
                  </Typography>
                  <NetworkPlot
                    data={networkData}
                    option={option}
                    highlightPath={true}
                    onClick={onNetworkClick}
                    height="35vh"
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={8}>
              <NetworkPlot
                data={networkData}
                option={option}
                highlightPath={false}
                onClick={onNetworkClick}
                height="80vh"
              />
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        // no monitor data could be found
        !loading && <Typography variant="subtitle1">No monitor data could be loaded</Typography>
      )}

      {/* Flag based display of loadingbar */}
      {loading && <LoadingBar />}

      {/* Flag based display of error snackbar */}
      {error.length > 0 && <ErrorSnackbar onClose={() => setError('')} message={error} />}

      {/* Flag based display of info snackbar */}
      {success.length > 0 && <InfoSnackbar onClose={() => setSuccess('')} message={success} />}
    </Fragment>
  );
}

export default CausalViewer;
