import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Button, Toolbar, Typography, createTheme } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Help from './help';

const theme = createTheme();

type AppHeaderProps = {};

type AppHeaderState = {
  showHelp?: boolean;
};

function AppHeader(props: AppHeaderProps) {
  const [showHelp, setShowHelp] = useState(localStorage.getItem('DontShowHelp') !== 'true');

  return (
    <AppBar sx={{ backgroundColor: '#0D1C3D' }}>
      <Toolbar sx={{ paddingLeft: theme.spacing(1) }}>
        <Button color="inherit" component={Link} to="/">
          <img
            src="/images/hcss_logo.png"
            alt="Logo HCSS"
            style={{ height: '6vh', backgroundColor: 'white', marginRight: theme.spacing(1) }}
          />
          <Typography variant="h1" sx={{ fontSize: '1.5em', color: 'white' }}>
            Climate Security Risk Monitor
          </Typography>
        </Button>
        <Typography variant="h1" sx={{ fontSize: '0.9em', color: 'white' }}>
          Explore which hazards cause insecurity, and how climate change developments cause
          insecurity.
        </Typography>
      </Toolbar>

      <Button
        sx={{
          position: 'fixed',
          top: theme.spacing(-0.7),
          right: theme.spacing(0.5),
          color: '#f50057',
          [theme.breakpoints.down('xs')]: {
            top: theme.spacing(-0.5),
            right: theme.spacing(1),
          },
        }}
        onClick={() => setShowHelp(true)}
      >
        <HelpOutlineIcon
          color="secondary"
          aria-label="add"
          sx={{
            fontSize: '4.5em',
            color: 'white',
            [theme.breakpoints.down('xs')]: {
              fontSize: '3.5em',
            },
          }}
        />
      </Button>
      <Help handleChange={() => setShowHelp(false)} showModal={showHelp} />
    </AppBar>
  );
}

export default AppHeader;
