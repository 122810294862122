import React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';

const ModelCard = ({
  modId,
  modTitle,
  description,
  imagePath,
}: {
  modId: string;
  modTitle: string;
  description: string;
  imagePath: string;
}) => (
  <Card>
    <CardActionArea>
      <CardMedia
        component="img"
        style={{
          width: 'auto',
          margin: 'auto',
          maxHeight: '100px',
        }}
        alt={`picture of model ${modId}`}
        image={imagePath}
      />

      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {modTitle}
        </Typography>
        <Typography component="p">{description}</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

export default ModelCard;
