import React from 'react';
import { ResponsiveChoropleth } from '@nivo/geo'

import { COLOR_SCHEMA } from '../services';
import worldjson from '../data/world_countries.json'
import ToolTip from './tooltip'

const Choropleth = ({
  hazard,
  data,
  onClick }) => {

  return (
    <div style={{ height: 400 }}>
      <ResponsiveChoropleth
        data={data}
        features={worldjson.features}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        colors={Object.values(COLOR_SCHEMA)}
        domain={[0, Object.keys(COLOR_SCHEMA).length]}
        unknownColor="#666666"
        label="properties.name"
        valueFormat=".2f"
        projectionTranslation={[0.5, 0.5]}
        projectionRotation={[0, 0, 0]}
        enableGraticule={true}
        graticuleLineColor="#dddddd"
        borderWidth={0.5}
        borderColor="#152538"
        onClick={onClick}
        tooltip={feature => {
          return <ToolTip id={feature.feature.id} hazard={hazard} />;
        }}
        legends={[
          {
            anchor: 'bottom-left',
            direction: 'column',
            justify: true,
            translateX: 20,
            translateY: -100,
            itemsSpacing: 0,
            itemWidth: 94,
            itemHeight: 18,
            itemDirection: 'left-to-right',
            itemTextColor: '#444444',
            itemOpacity: 0.85,
            symbolSize: 18,
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000000',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  )
}

export default Choropleth;
