import React, { Fragment } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';

import { ModelService } from '../services';
import { Entity, Country } from '../model';

const modelService = ModelService.getInstance();

const ToolTip = ({ id, hazard }: { id: string; hazard: string }) => {
  const entity: Entity = modelService.getCountryData(id);
  const country: Country = modelService.getCountry(id);
  const columns: string[] = modelService.getColumns('ToolTip', hazard);

  return (
    <Fragment>
      {entity && (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2">
              {country?.name}
            </Typography>

            <Typography>The risk is comprised of the following underlaying data:</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Exposure
                    </TableCell>
                    <TableCell>
                      <b>{Number(entity[columns[0] as keyof Entity]).toFixed(2)}</b> out of 100
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Hazard
                    </TableCell>
                    <TableCell>
                      <b>{Number(entity[columns[1] as keyof Entity]).toFixed(2)}</b> out of 100
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Vulnerability
                    </TableCell>
                    <TableCell>
                      <b>{Number(entity[columns[2] as keyof Entity]).toFixed(2)}</b> out of 100
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Susceptibility
                    </TableCell>
                    <TableCell>
                      <b>{Number(entity[columns[3] as keyof Entity]).toFixed(2)}</b> out of 100
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardActions>
            <Typography>See the Climate Security Risk Methodology for more detail.</Typography>
          </CardActions>
        </Card>
      )}
    </Fragment>
  );
};

export default ToolTip;
