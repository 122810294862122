import React, { Fragment } from 'react';
import { Typography } from '@mui/material';

import variableExplanation from '../data/variable_explanation.json';

const NodeInformation = ({ data }: { data: string }) => {
  const explanation = variableExplanation.find((element) => element.variable === data);

  return (
    <Fragment>
      <Typography gutterBottom variant="h6" component="h2">
        Variable Definition
      </Typography>
      <Typography component="p">{explanation?.explanation}</Typography>
    </Fragment>
  );
};

export default NodeInformation;
