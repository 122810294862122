import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

type DataTableProps = {
  data: any;
  columns: any;
};
type DataTableState = {};

function DataTable(props: DataTableProps) {
  const { data, columns } = props;

  return (
    <div style={{ height: 400, width: '100%', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
      <DataGrid
        rows={data}
        columns={columns}
      />
    </div>
  );
}

export default DataTable;
