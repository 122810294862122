import React from 'react';
import { Snackbar, SnackbarContent, IconButton, Theme, createTheme } from '@mui/material';
import { Error as ErrorIcon, Close as CloseIcon } from '@mui/icons-material';

const theme = createTheme();

const AUTO_HIDE_DURATION = 6000;

const ErrorSnackbar = ({ message, onClose }: { message: string; onClose: any }) => (
  <Snackbar open autoHideDuration={AUTO_HIDE_DURATION} onClose={onClose}>
    <SnackbarContent
      sx={{
        backgroundColor: theme.palette.error.dark,
      }}
      message={
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ErrorIcon
            sx={{
              fontSize: 20,
              opacity: 0.9,
              marginRight: theme.spacing(1),
            }}
          />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon
            sx={{
              fontSize: 20,
            }}
          />
        </IconButton>,
      ]}
    />
  </Snackbar>
);

export default ErrorSnackbar;
