import React, { useEffect, useState, Fragment } from 'react';
import { Typography, Grid } from '@mui/material';

import Selection from '../components/selection';
import ErrorSnackbar from '../components/errorSnackbar';
import InfoSnackbar from '../components/infoSnackbar';
import LoadingBar from '../components/loadingBar';
import ScatterPlot from '../components/scatterPlot';
import Choropleth from '../components/choropleth';
import BulletPlot from '../components/bulletPlot';

import { ModelService } from '../services';
import {
  ChoroplethEntry,
  ScatterPlotEntry,
  Country,
  BulletPlotData,
  ScatterPlotDataPoint,
} from '../model';

const HAZARDS = [
  'Coastal_Flooding',
  'Droughts',
  'Heatwaves',
  'Landslides',
  'Riverine_Flooding',
  'Tropical_Storms',
  'Wildfires',
].sort();

const RISK_LEVELS = ['Low', 'Medium-low', 'Medium', 'Medium-high', 'High'];

type MonitorViewerProps = {};

type MonitorViewerState = {
  monitorId: string;
  monitorReadable: string;
  countries: Country[];
  countries_selected: string[];
  riskLevels_selected: string[];
  scatterPlotData: ScatterPlotEntry[];
  scatterPlotData_selected: ScatterPlotEntry[];
  choroplethData: ChoroplethEntry[];
  choroplethData_selected: ChoroplethEntry[];
  bulletPlotData: BulletPlotData[];

  service: ModelService;

  loading: boolean;
  success: string;
  error: string;
};

function MonitorViewer(props: MonitorViewerProps) {
  const [monitorId, setMonitorId] = useState<string>('');
  const [monitorReadable, setMonitorReadable] = useState<string>('');
  const [countries, setCountries] = useState<Country[]>([]);
  const [countries_selected, setCountries_selected] = useState<string[]>([]);
  const [riskLevels_selected, setRiskLevels_selected] = useState<string[]>([]);
  const [scatterPlotData, setScatterPlotData] = useState<ScatterPlotEntry[]>([]);
  const [scatterPlotData_selected, setScatterPlotData_selected] = useState<ScatterPlotEntry[]>([]);
  const [choroplethData, setChoroplethData] = useState<ChoroplethEntry[]>([]);
  const [choroplethData_selected, setChoroplethData_selected] = useState<ChoroplethEntry[]>([]);
  const [bulletPlotData, setBulletPlotData] = useState<BulletPlotData[]>([]);

  const [service, setService] = useState<ModelService>(ModelService.getInstance());

  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    let monitorId = 'Coastal_Flooding';
    let monitorReadable = 'Coastal Flooding';

    if (window.location.pathname.split('/').length > 2 && window.location.pathname.split('/')[2] !== '') {
      monitorId = window.location.pathname.split('/').pop() || '';
      monitorReadable = monitorId.replaceAll('_', ' '); // create printable monitor name based on ID
    }

    setMonitorId(monitorId);
    setMonitorReadable(monitorReadable);
  }, []);

  useEffect(() => {
    getMonitorData();
  }, [monitorId]);

  const getMonitorData = async () => {
    let scatterPlotData = await getScatterPlotData();
    let choroplethData = await getChoroplethData();
    let countries = await getCountries();

    setScatterPlotData(scatterPlotData);
    setScatterPlotData_selected(scatterPlotData);
    setChoroplethData(choroplethData);
    setChoroplethData_selected(choroplethData);
    setCountries(countries);
    setLoading(false);
  };

  const getCountries = async () => {
    try {
      return await service.getCountries();
    } catch {
      setError('Could not talk with backend');

      return [];
    }
  };

  const getScatterPlotData = async () => {
    try {
      return await service.getScatterPlotData(monitorId);
    } catch {
      setError('Could not talk with backend');
    }

    return [];
  };

  const getChoroplethData = async () => {
    try {
      return await service.getChoroplethData(monitorId);
    } catch {
      setError('Could not talk with backend');
    }

    return [];
  };

  const handleCountryChange = (evt: React.ChangeEvent<{ value: unknown }>) => {
    setCountries_selected((evt.target.value as string[]).sort());
    filterDataSet();
  };

  const handleRiskLevelChange = (evt: React.ChangeEvent<{ value: unknown }>) => {
    setRiskLevels_selected((evt.target.value as string[]).sort());
    filterDataSet();
  };

  const handleHazardChange = (evt: React.ChangeEvent<{ value: unknown }>) => {
    let hazard = evt.target.value as string;

    if (hazard) {
      setMonitorId(hazard);
      setMonitorReadable(hazard.replaceAll('_', ' ')); // create printable monitor name based on ID
      getMonitorData();
    } else {
      setMonitorId('');
      setMonitorReadable('');
      setCountries_selected([]);
      setScatterPlotData([]);
      setChoroplethData([]);
      setScatterPlotData_selected([]);
      setChoroplethData_selected([]);
    }
  };

  const filterDataSet = () => {
    // this plot is only supported for one country
    if (countries_selected.length === 1) {
      service
        .getBulletPlotData({ name: '', iso3c: countries_selected[0] })
        .then((bulletPlotData) => {
          setBulletPlotData(bulletPlotData);
        });
    }

    let output: { data: ScatterPlotDataPoint[]; id: string }[] = [];

    scatterPlotData.forEach((element) => {
      if (riskLevels_selected.length === 0) {
        output.push({
          ...element,
          data: element.data.filter((data) => countries_selected.includes(data.country)),
        });
      }

      if (countries_selected.length === 0 && riskLevels_selected.includes(element.id)) {
        output.push({
          ...element,
          data: element.data,
        });
      }

      if (riskLevels_selected.includes(element.id)) {
        output.push({
          ...element,
          data: element.data.filter((data) => countries_selected.includes(data.country)),
        });
      }
    });

    setChoroplethData(
      choroplethData.filter((element) => {
        if (countries_selected.length === 0)
          return riskLevels_selected.includes(element.risk_label);
        if (riskLevels_selected.length === 0) return countries_selected.includes(element.id);

        return (
          countries_selected.includes(element.id) &&
          riskLevels_selected.includes(element.risk_label)
        );
      })
    );
    setScatterPlotData(output);
  };

  const onClick = (node: any) => {
    if (!countries_selected.includes(node.id)) {
      let countries = countries_selected;
      countries.push(node.id);

      setCountries_selected(countries);
      filterDataSet();
    } else {
      let countries = countries_selected.filter((element) => element !== node.id);
      setCountries_selected(countries);
      filterDataSet();
    }
  };

  return (
    <Fragment>
      <Typography variant="h4">Hazard View</Typography>

      {/* Dropdown selection for filter */}
      <Selection
        id="hazard"
        inputName="hazard"
        labelName="Hazard"
        selectedValues={monitorId}
        values={HAZARDS}
        handleChange={handleHazardChange}
      />

      {monitorId && (
        // monitor data present
        <Fragment>
          <Typography variant="h5">{monitorReadable}</Typography>
          <Selection
            id="country-selection"
            multiple={true}
            inputName="country"
            labelName="Countries"
            selectedValues={countries_selected}
            values={countries}
            keyProperty="iso3c"
            valueProperty="name"
            handleChange={handleCountryChange}
          />

          <Selection
            id="risk-level-selection"
            multiple={true}
            inputName="risk-level"
            labelName="Risk Level"
            selectedValues={riskLevels_selected}
            values={RISK_LEVELS}
            handleChange={handleRiskLevelChange}
          />

          <Grid container spacing={2}>
            {/* Cross Country Hazard View - only if one country is selected */}
            {countries_selected.length == 1 && (
              <Grid item xs={12}>
                <Typography variant="h6">Cross Hazard Country Plot</Typography>
                <BulletPlot data={bulletPlotData} id={countries_selected[0]}></BulletPlot>
              </Grid>
            )}

            {/* Choropleth */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Total Risk of Insecurity</Typography>
              <Choropleth hazard={monitorId} data={choroplethData_selected} onClick={onClick} />
              <Typography>
                *Risk is measured as a function of the probability of a natural hazard to occur and
                the potential adverse impacts this hazard could cause within a society.
              </Typography>
              <Typography>*Risk = Impact * Probability</Typography>
            </Grid>

            {/* Scatterplot */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Probability and Impact on Insecurity</Typography>
              <ScatterPlot hazard={monitorId} data={scatterPlotData_selected} onClick={onClick} />
              <Typography>
                *Probability refers to the likelihood of a (natural) disaster to occur.
              </Typography>
              <Typography>
                *Impact refers to the prospective adverse consequences of future climaterelated
                disasters on natural and human systems
              </Typography>
            </Grid>
          </Grid>
        </Fragment>
      )}

      {/* Flag based display of loadingbar */}
      {loading && <LoadingBar />}

      {/* Flag based display of error snackbar */}
      {error.length > 0 && <ErrorSnackbar onClose={() => setError('')} message={error} />}

      {/* Flag based display of info snackbar */}
      {success.length > 0 && <InfoSnackbar onClose={() => setSuccess('')} message={success} />}
    </Fragment>
  );
}

export default MonitorViewer;
