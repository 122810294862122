import React from 'react';
import { createTheme } from '@mui/material';

const theme = createTheme();

const Footer = ({}: {}) => (
  <div
    style={{
      position: 'fixed',
      bottom: 0,
      margin: theme.spacing(4),
    }}
  ></div>
);

export default Footer;
